<div class="row mt-1">
  <div class="{{Constants.Layout.fullWidth}} + {{' d-flex justify-content-center'}}">
    <ib-button *ngIf="showSave" name="Save Form Button" icon="save" label="Save" color="primary"
      buttonClasses="btn-save" [formStatus]="formStatus" [displayErrorMessages]="false" [working]="working"
      (click)="fireSave($event)">
    </ib-button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <ib-button *ngIf="showClose" name="Back" icon="reply (solid)" label="Back" color="default"
      (click)="fireClose($event)">
    </ib-button>
  </div>
</div>
<div class="row" *ngIf="showSave">
  <div class="{{Constants.Layout.fullWidth}} + {{' d-flex justify-content-center'}}">
    <ib-form-status-error-output [formStatus]="formStatus"></ib-form-status-error-output>
  </div>
</div>
