<div
  *ngIf="options.globalSearch || ( options.staticFilterPickList && options.staticFilterPickList.length > 0 ) || options.actionButtonLeft1 || options.actionButtonLeft2 || options.actionButtonRight1 || options.actionButtonRight2">
  <div *ngIf="options.actionButtonLeft1" class="float-left mr-2">
    <ib-action-button [button]="options.actionButtonLeft1"
      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div *ngIf="options.actionButtonLeft2" class="float-left mr-2">
    <ib-action-button [button]="options.actionButtonLeft2"
      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div *ngIf="options.globalSearch" class="float-left">
    <i [ngClass]="{'text-danger fas': globalFilterText, 'far': !globalFilterText, 'fa-search': true}"
      style="margin:10px 10px 0 0"></i>
  </div>
  <div *ngIf="options.globalSearch" class="float-left">
    <ib-input-plain type="text" size="small" placeholder="Search" [wrapperStyles]="'width:300px;'" [standalone]="true"
      [(ngModel)]="globalFilterText" (keyUp)="filterGlobalOnInput($event)" (change)="filterGlobalOnChange($event)">
    </ib-input-plain>
  </div>
  <div *ngIf="options.staticFilterPickList && options.staticFilterPickList.length > 0" class="float-left">
    <ib-input-select-plain name="Static Filter" size="small" tooltip="{{options.staticFilterLabel}}"
      [wrapperStyles]="'width:300px;'" [optionsPickList]="options.staticFilterPickList" [optionsIncludeNone]="false"
      [(ngModel)]="options.staticFilterValue">
    </ib-input-select-plain>
  </div>
  <div *ngIf="options.actionButtonRight2" class="float-right">
    <ib-action-button [button]="options.actionButtonRight2"
      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div *ngIf="options.actionButtonRight1" class="float-right mr-2">
    <ib-action-button [button]="options.actionButtonRight1"
      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div class="clearfix"></div>
</div>
<div
  class="p-datatable-component-plain p-datatable ui-widget p-datatable-responsive p-datatable-resizable p-datatable-resizable-fit p-datatable-hoverable-rows p-datatable-auto-layout">
  <div class="p-datatable-wrapper">
    <div class="flex-table p-datatable-striped" style="width:100%;">
      <div class="flex-table-row flex-table-header">
        <div *ngIf="options.reorderRows" class="flex-table-row-item" style="width:2.5em;"></div>
        <div class="flex-table-row-item" *ngFor="let col of options.columns;trackBy: trackByIndex">
          <!-- <div class="float-left"> -->
          <ib-icon [icon]="col.headerIcon" [label]="col.header" [tooltip]="col.headerTooltip"></ib-icon>
          <!--<p-sortIcon *ngIf="options.sortMode !== 'none' && col.sortable" [field]="col.field"></p-sortIcon>-->
          <!-- </div> -->
          <!--<div *ngIf="options.filterColumns && col.filterType !== 'none'" class="float-left" style="padding: 1px 5px 1px 5px; cursor:pointer;" (click)="filterColumn($event, col, filterPanel)">
              <i [ngClass]="{'text-danger fas': (col.filterValue||col.filterSelections.length>0), 'far': (!col.filterValue&&col.filterSelections.length===0), 'fa-filter': true}"></i>
            </div>-->
        </div>
        <div *ngIf="options.rowActionButton" class="flex-table-row-item" style="width:2.5em;">
          <div class="text-center">
            <ib-icon icon="bars"></ib-icon>
          </div>
        </div>
      </div>
      <div class="p-datatable-tbody" cdkDropList [cdkDropListData]="data" (cdkDropListDropped)="rowDrop($event)"
        #tablebody>
        <div class="flex-table-row ui-selectable-row" *ngFor="let row of data; let index = index; trackBy: trackByIndex"
          cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="!options.reorderRows">
          <!--<div class="drop-placeholder" *cdkDragPlaceholder></div>-->
          <div class="flex-table-row-item drag-col-xs ui-resizable-column" *ngIf="options.reorderRows"
            style="width:2.5em;">
            <i class="far fa-arrows" cdkDragHandle></i>
          </div>
          <div class="flex-table-row-item drag-col-md ui-resizable-column"
            *ngFor="let col of options.columns;trackBy: trackByIndex" (mousedown)="onRowClick($event, row, index)">
            <!--{{row[col.propertyName]}}-->
            <ib-standard-table-cell-output [options]="col" [data]="getModel(row, col.propertyName)" [row]="row"
              [refreshCount]="otherChangeCount">
            </ib-standard-table-cell-output>
          </div>
          <div class="flex-table-row-item drag-col-xs ui-resizable-column rowMenuCell" *ngIf="options.rowActionButton"
            style="width:2.5em;" (click)="showMenu(rowMenu,$event,headerRow,-1,headerIndex)">
            <!-- <ib-action-button [button]="options.rowActionButton" [data]="row" [cargo]="{ index: index }">
            </ib-action-button> -->
            <!-- <ib-icon icon="bars"></ib-icon> -->
            <div class="text-center rowMenuCell">
              <ib-icon icon="bars" class="rowMenuCell"></ib-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-contextMenu #cxtMenu [target]="tablebody" [model]="contextMenu" appendTo="body" [baseZIndex]="2000">
</p-contextMenu>
<p-menu #rowMenu [popup]="true" [model]="contextMenu" appendTo="body"></p-menu>


<!-- <div class="flex-table">
<div class="flex-table-row flex-table-header">
  <div class="flex-table-row-item u-flex-grow2">Long Header1</div>
  <div class="flex-table-row-item">Header2</div>
  <div class="flex-table-row-item">Header3</div>
  <div class="flex-table-row-item">Header4</div>
  <div class="flex-table-row-item u-flex-grow3">Longer Header5</div>
  <div class="flex-table-row-item">Header6</div>
  <div class="flex-table-row-item">Header7</div>
</div>
<div class="flex-table-row">
  <div class="flex-table-row-item u-flex-grow2" data-header="Header1">row2 col1</div>
  <div class="flex-table-row-item" data-header="Header2">row2 col2</div>
  <div class="flex-table-row-item" data-header="Header3">row2 col3</div>
  <div class="flex-table-row-item" data-header="Header4">row2 col4</div>
  <div class="flex-table-row-item u-flex-grow3" data-header="Header5">row2 col5</div>
  <div class="flex-table-row-item" data-header="Header6">row2 col6</div>
  <div class="flex-table-row-item" data-header="Header7">row2 col7</div>
</div>
<div class="flex-table-row">
  <div class="flex-table-row-item u-flex-grow2" data-header="Header1">row3 col1</div>
  <div class="flex-table-row-item" data-header="Header2">row3 col2</div>
  <div class="flex-table-row-item" data-header="Header3">row3 col3</div>
  <div class="flex-table-row-item" data-header="Header4">row3 col4</div>
  <div class="flex-table-row-item u-flex-grow3" data-header="Header5">row3 col5</div>
  <div class="flex-table-row-item" data-header="Header6">row3 col6</div>
  <div class="flex-table-row-item" data-header="Header7">row3 col7</div>
</div>
</div> -->
