import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';

@Component({
  selector: 'ib-form-save-close-buttons',
  templateUrl: './form-save-close-buttons.component.html',
  styleUrls: ['./form-save-close-buttons.component.css']
})
export class FormSaveCloseButtonsComponent implements OnInit {

  @Input() public formStatus: FormStatusModel;
  @Input() public showSave: boolean = true;
  @Input() public showClose: boolean = true;
  @Input() public working: boolean = false;
  @Output() public save: EventEmitter<EventModel> = new EventEmitter();
  @Output() public close: EventEmitter<EventModel> = new EventEmitter();

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor() { }

  ngOnInit() {
  }

  protected fireSave($event) {
    // Do not emit any click events when the button is disabled
    if (this.formStatus && this.formStatus.isValid && !this.formStatus.isPristine) {
      let payload: EventModel = new EventModel("click", $event, true, new EventElementModel("button", null, "Save"));
      this.save.emit(payload);
    }
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more dev friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      Log.errorMessage(err);
    }
  }

  protected fireClose($event) {
    let payload: EventModel = new EventModel("click", $event, false, new EventElementModel("button", null, "Close"));
    this.close.emit(payload);
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more dev friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      Log.errorMessage(err);
    }
  }

}
