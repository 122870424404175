import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { EventModelTyped } from '../../ux-models';
import { UxService } from '../../services/ux.service';
//import { PercentMaskConfig, PercentMaskInputMode } from 'ngx-percent';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputPercentComponent),
  multi: true
};

@Component({
  selector: 'ib-input-percent',
  templateUrl: './input-percent.component.html',
  styleUrls: ['./input-percent.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputPercentComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public min: number;
  @Input() public max: number;
  @Input() public step: number;

  @Input() public decimalPlaces: number;
  @Input() public modelIsDecimal: boolean;

  displayValue: number;
  suffixText: string = "%";

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes)
    this.configure();
  }

  get value(): any {
    // make all values available by UnitCode
    return this.modelIsDecimal ? this.displayValue / 100 : this.displayValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.handleNewValue(v);
    }
  }

  writeValue(v: number) {
    super.writeValue(v);
    this.handleNewValue(v);
  }

  handleNewValue(val: number) {
    this.displayValue = this.modelIsDecimal ? val * 100 : val;
    console.log(this.value)
    this.reportChange();
  };

  reportChange() {
    this.onChangeCallback(this.value);
  }


}
